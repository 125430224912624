import { useState } from "react";
import assets from "../constants/assets";
import ShadowRoundedBox from "./ShadowRoundedBox";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

const TestimonialSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      image: assets.kott,
      title: "Petr Kott",
      subtitle: "CEO at Peko Studio",
      text: "Spolupráce s Pavlem na vývoji našich mobilních aplikací pro iOS byla a je pozitivní a přínosná. Pavel odvedl kvalitní práci, díky které naše aplikace splňují požadované funkce a jsou uživatelsky přívětivé. Oceňuji jeho snahu efektivně komunikovat a přizpůsobit se specifickým požadavkům projektu, což pomohlo projekt posunout vpřed.",
      alt: "Petr Kott",
    },
    {
      image: assets.danova,
      title: "Hana Daňová",
      subtitle: "Project Manager at Peko Studio",
      text: "Pavel s námi pracuje na vývoji mobilních aplikací jako developer ve Flutteru a SwiftUI a je to spolupráce velmi příjemná. Pavel přistupuje k úkolům svědomitě, zodpovědně a přináší i spoustu vlastních podnětů pramenících z jeho zkušeností. Doufám, že spolu zažijeme ještě hodně nových krásných projektů :)",
      alt: "Hana Daňová",
    },
    {
      image: assets.mandik,
      title: "Matyáš Mandík",
      subtitle: "Co-founder at Pixelmate",
      text: "S Pavlem spolupracujeme dlouhodobě a vždy vše proběhlo v naprostém pořádku. Termíny se vždy stihly a vše ve výborné kvalitě. Pavel je super i jako člověk, vždy se na všem domluvíme.",
      alt: "Matyáš Mandík",
    },
    {
      image: assets.chundelova,
      title: "Kateřina Chundelová",
      subtitle: "Project Manager at Pixelmate",
      text: "Pavel je nejen zkušený vývojář, který dodržuje domluvené termíny, ale vždy jde ještě o kus dál. Neplní pouze zadané řešení, ale sám přichází s nápady, jak každý projekt vylepšit, ať už se jedná o návrh nové funkce nebo zlepšení UX/UI. Pokud se během vývoje objeví problém, je schopný navrhnout několik možných řešení, aby si klient mohl vybrat přesně podle svých potřeb a možností.",
      alt: "Kateřina Chundelová",
    },
    {
      image: assets.fialova,
      title: "Tereza Fialová",
      subtitle: "Freelancer",
      text: "S Pavlem spolupracujeme dlouhodobě na vývoji mobilních aplikací a ve všech projektech se jednalo o příjemnou spolupráci. Oceňuji hlavně jeho profesionalitu, proaktivitu a návrh vhodných řešení, kvalitu výsledné práce a schopnost dodržet domluvené termíny.",
      alt: "Tereza Fialová",
    },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <ShadowRoundedBox
        image={testimonials[currentIndex].image}
        title={testimonials[currentIndex].title}
        subtitle={testimonials[currentIndex].subtitle}
        text={testimonials[currentIndex].text}
        alt={testimonials[currentIndex].alt}
        styles={"min-h-[470px] md:min-h-[400px] md:max-w-4xl"}
        badge={assets.quote}
        badgeSize={8}
      />
      <div className="flex justify-center gap-2 mt-2">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`w-3 h-3 rounded-full ${
              index === currentIndex ? "bg-indigo-500" : "bg-gray-200"
            }`}
          />
        ))}
      </div>
      <div className="flex gap-2 justify-between bg-gray-100 p-3 rounded-full w-36 self-center">
        <button
          onClick={handlePrev}
          className="p-3 rounded-full bg-indigo-500 hover:bg-indigo-600"
        >
          <ChevronLeftIcon className="size-6 text-white self-center" />
        </button>
        <button
          onClick={handleNext}
          className="p-3 rounded-full bg-indigo-500 hover:bg-indigo-600"
        >
          <ChevronRightIcon className="size-6 text-white self-center" />
        </button>
      </div>
    </div>
  );
};

export default TestimonialSlider;
