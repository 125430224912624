import { useEffect } from "react";
import Header from "./components/Header";
import Technologies from "./components/Technologies";
import Section from "./components/Section";
import MyService from "./components/MyService";
import assets from "./constants/assets";
import ProgressBar from "./components/ProgressBar";
import Footer from "./components/Footer";
import ShadowRoundedBox from "./components/ShadowRoundedBox";
import ReactGA from "react-ga4";
import TestimonialSlider from "./components/TestimonialSlider";

const App = () => {
  useEffect(() => {
    document.title = "Pavel Hřebíček | Mobile developer";
    ReactGA.initialize("G-VDE3N8122V");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div>
      <div className="px-6 md:px-12 pb-12">
        <Header />
        <Technologies />
        <div className="flex flex-col gap-16">
          <Section title={"O mně"}>
            <div className="flex flex-col gap-4">
              <span className="max-w-4xl text-center">
                V roce 2019 jsem dokončil inženýrské studium na Fakultě
                informačních technologií VUT v Brně. Již při studium jsem začal
                pracovat jako React Native Developer a začal sbírat první
                zkušenosti s mobilním vývojem. Po ukončení studia jsem se vydal
                cestou freelancera. Dávalo mi to větší smysl, jelikož jsem chtěl
                být blíže klientovi. Díky tomu mohu do detailů pochopit a
                naplnit očekávání klienta, což je pro mě ta nejdůležitější
                hodnota v celém procesu vývoje.
              </span>
            </div>
          </Section>
          <Section title={"Moje služby"}>
            <div className="flex flex-col md:flex-row gap-7">
              <MyService
                image={assets.mobile}
                title={"Mobilní aplikace"}
                text={
                  "Vývoj mobilních iOS a Android aplikací na míru. Pro vývoj využívám technologie React Native, Flutter nebo SwiftUI."
                }
              />
              <MyService
                image={assets.consultation}
                title={"IT konzultace"}
                text={
                  "Potřebujete poradit s výběrem správné technologie? Potřebuje cenový odhad projektu? Neumíte si představit, co vše vývoj mobilní aplikace obnáší? Od toho jsem tu pro Vás."
                }
              />
            </div>
          </Section>
          <Section title={"Moje dovednosti"}>
            <div className="flex flex-col gap-4 w-full md:max-w-4xl">
              <ProgressBar title={"React Native"} completed={95} />
              <ProgressBar title={"Flutter"} completed={85} />
              <ProgressBar title={"Swift/SwiftUI"} completed={65} />
            </div>
          </Section>
          <Section title={"Co o mně říkají klienti a kolegové"}>
            <TestimonialSlider />
          </Section>
          <Section title={"Moje poslední práce"}>
            <div className="flex flex-col md:flex-row gap-10 md:gap-14 md:pt-4">
              <ShadowRoundedBox
                image={assets.progresguru}
                title={"ProgresGuru"}
                subtitle={"Mobilní aplikace"}
                text={"Mobilní aplikace pro zakoupení a poslech audioknih."}
                alt={"ProgresGuru"}
                badge={assets.swift_logo}
                textAlign="text-center"
                styles={"max-w-96"}
              />
              <ShadowRoundedBox
                image={assets.vycistimeto}
                title={"Vyčistíme.to"}
                subtitle={"Mobilní aplikace"}
                text={
                  "Mobilní aplikace pro digitalizaci a usnadnění interních procesů ve firmě."
                }
                alt={"Vyčistíme.to"}
                badge={assets.swift_logo}
                textAlign="text-center"
                styles={"max-w-96"}
              />
            </div>
          </Section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
